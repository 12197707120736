import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import AllCategoriesDropdown from "./AllCategoriesDropdown";
import Logo from "../../../logoo.png";
import "./Navbar.css";

const NavbarComponent = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // State for screen size
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu visibility
  const location = useLocation();

  // Update `isMobile` based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); // Adjust breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update `isSticky` based on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight - 80) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location.pathname]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const MobileNavbar = () => (
    <div className="mobile-navbar">
      <img src={Logo} alt="Logo" className="mobile-navbar-logo" />
      <div className="hamburger-icon" onClick={toggleMenu}>
        {/* Hamburger icon */}
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
      </div>

      {menuOpen && (
        <div className="mobile-menu-popup">
          <button className="close-icon" onClick={() => setMenuOpen(false)}>
            &times;
          </button>
          <Nav className="mobile-nav">
            <Nav.Link
              as={NavLink}
              to="/"
              exact
              onClick={() => setMenuOpen(false)}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/aboutpage"
              onClick={() => setMenuOpen(false)}
            >
              About Us
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/gallery"
              onClick={() => setMenuOpen(false)}
            >
              Products
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/Newsevent"
              onClick={() => setMenuOpen(false)}
            >
              News & Events
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/contact"
              onClick={() => setMenuOpen(false)}
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </div>
      )}
    </div>
  );

  const HeroNavbar = () => (
    <div className="hero-navbar">
      <div className="hero-navbar-container">
        <Navbar.Brand as={NavLink} to="/" className="hero-navbar-brand">
          <img src={Logo} className="hero-logoimg" alt="Logo" />
        </Navbar.Brand>
        <Navbar expand="lg" className="w-100">
          <div className="hero-toggle-div">
            <Container className="d-flex justify-content-between">
              <div className="d-none d-lg-flex">
                <Nav className="hero-navbar-nav">
                  <Nav.Link as={NavLink} to="/" exact>
                    Home
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/aboutpage">
                    About Us
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/gallery">
                    Products
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/Newsevent">
                    News & Events
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/contact">
                    Contact Us
                  </Nav.Link>
                  <div className="box123">
                    <AllCategoriesDropdown dropDirection="up" />
                  </div>
                </Nav>
              </div>
            </Container>
          </div>
        </Navbar>
      </div>
    </div>
  );

  const StickyNavbar = () => (
    <div className="sticky-navbar">
      <div className="sticky-navbar-container">
        <Navbar.Brand as={NavLink} to="/" className="sticky-navbar-brand">
          <img src={Logo} className="sticky-logo-img" alt="Logo" />
        </Navbar.Brand>
        <Navbar expand="lg" className="w-100">
          <div className="sticky-toggle-div">
            <Container className="d-flex justify-content-between">
              <div className="d-none d-lg-flex">
                <Nav className="sticky-navbar-nav">
                  <Nav.Link as={NavLink} to="/" exact>
                    Home
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/aboutpage">
                    About Us
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/gallery">
                    Products
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/Newsevent">
                    News & Events
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/contact">
                    Contact Us
                  </Nav.Link>
                  <AllCategoriesDropdown dropDirection="down" />
                </Nav>
              </div>
            </Container>
          </div>
        </Navbar>
      </div>
    </div>
  );

  return (
    <div>
      {isMobile ? (
        <MobileNavbar />
      ) : (
        <>
          {location.pathname === "/" && !isSticky && <HeroNavbar />}
          {(location.pathname !== "/" || isSticky) && <StickyNavbar />}
        </>
      )}
    </div>
  );
};

export default NavbarComponent;
